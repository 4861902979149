<template>
    <div class="recover">

        <br><h4>Reenviar E-mail de Verificação</h4>

        <div class="col-md-6 offset-md-3" style="padding: 10px">
            <form class="form form-horizontal" @submit.prevent="resendCode()">
                <div class="form-group">
                    <label> E-mail </label>
                    <input type="text" class="form-control" v-model="email" required>
                </div>

                <input type="submit" class="btn btn-send" value="RE-ENVIAR">

                <div v-if="msg" class="msg alert alert-info"> {{msg}} </div>
            </form>
        </div>

    </div>
</template>

<script>
import api from '../api';
export default {
    data() {
        return {
            email: '',
            pass: '',
            msg: '',
        }
    },
    methods: {
        resendCode() {
            let vm = this
            let email = this.email

            api.post('/resendCode', {
                email
            })
            .then(function(response) {
                console.log(">>",response.data)
                if(response.data){
                    vm.msg = response.data.msg
                }
            })
            .catch(function(error) {
                console.log(error)
            })
        }
    }
}
</script>

<style scoped>
    .btn-send {
        background-color: #2D158B;
        color: #F2F2F2;
        width: 100%;
        border: none;
        margin: 10px auto;
    }
</style>