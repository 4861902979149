<template>
  <div class="home">
    <div id="nav-menu">
      <a href="javascript:void(0)" @click="logout()">Sair</a>
    </div>

    <h1> MINHA PONTUAÇÃO </h1>
    <p> {{msg}} </p>

    <div class="status points">
        <h2> <strong> Pontos: </strong> {{user.points}} </h2>
    </div>

    <div class="status code">
        <h2> <strong> Seu Código: </strong> {{user.code}} </h2> <br>

        <div class="alert alert-info">
            <h3>Indique e Ganhe Pontos!</h3>
            <small>Envie seu código para seus amigos e <b>ganhe 10 pontos</b> a cada cadastro efetivado.</small> <br>
            <small>Participe de diferentes <b>sorteios</b> com base na sua pontuação!</small> <br> <br>
            <div class="col-md-4 offset-md-4" style="text-align:center"> 
                <strong> Link de Indicação: </strong> <br> 
                <input type="text" class="form-control" style="text-align:center" :value="baseUrl + '/?refId=' + user.code + '&act=register'" id="link_code" @click="copyToClipboard()" />  
                <span v-if="copied" class="copied">{{copied}}</span>
            </div>
        </div>
    </div>

    <div class="status social">
        <a href="https://instagram.com/calangodoidonft" target="_blank"> Siga no Instagram </a> &nbsp;
        <a href="https://twitter.com/calangodoidonft" target="_blank"> Siga no Twitter</a>
    </div>
  </div>
</template>

<script>
import api from '../api';
export default {
    data(){
        return {
            msg: '',
            user: '',
            baseUrl: window.location.host,
            copied: ''
        }
    },
    methods: {
        loadUserData() {
            let vm = this
            let _token = localStorage.getItem('token_clng')
            api.get(`/loadUserData`, {
                headers: {
                    'Authorization': 'Bearer ' + _token
                }
            })
            .then(function(response) { 
                if(response.data){
                    vm.msg = response.data.msg
                    vm.user = response.data
                } else {
                    vm.$router.push({name: "Home"})
                }
            })
            .catch(function() {
                localStorage.setItem('token_clng', '')
                vm.$router.push({name: "Home"})
            })
        },
        logout() {
            localStorage.setItem('token_clng', '');
            this.$router.push({name: 'Home'});
        },
        copyToClipboard() {
            var copyText = document.getElementById("link_code");

            copyText.select();
            copyText.setSelectionRange(0, 99999);

            navigator.clipboard.writeText(copyText.value);

            this.copied = "Link copiado!";
        }
    },
    mounted() {
        this.loadUserData()
    }
}
</script>

<style>
    .status {
        padding: 10px;
    }
    .social a {
        text-decoration: none;
        color: #FFF;
        padding: 15px;
        background-color: #2D158B;
        border-radius: 50px;
    }
    .copied {
        background-color: orange;
        color: #222;
        padding: 5px 10px;
        border-radius: 50px;
    }
</style>