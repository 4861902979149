<template>
    <div class="register">

        <h1>CADASTRAR</h1>

        <div class="col-md-4 offset-md-4" style="padding: 10px">
            <form class="form-horizontal" @submit.prevent="register()">
                <div class="form-group">
                    <label> E-mail </label>
                    <input type="text" class="form-control" v-model="email">
                </div>

                <div class="form-group">
                    <label> Senha </label>
                    <input type="password" class="form-control" v-model="pass">
                </div>

                <div class="form-group">
                    <label> Código de indicação </label>
                    <input type="text" class="form-control" v-model="code">
                </div>

                <input type="submit" class="btn btn-send" value="CADASTRAR">

                <div class="msg alert alert-info" v-if="msg"> {{msg}} </div>
                <div class="msg alert alert-danger" v-if="Object.keys(errors).length > 0"> <span v-for="(err, index) in errors" :key="index"> {{err.message}} <br> </span> </div>
            </form>
        </div>

    </div>
</template>

<script>
import api from '../api';
export default {
    data() {
        return {
            email: '',
            pass: '',
            code: '',
            msg: '',
            errors: {}
        }
    },
    methods: {
        register() {
            let vm = this
            vm.errors = {}
            let email = this.email
            let password = this.pass
            let code = this.code

            api.post('/post', {
                email, password, code
            })
            .then(function(response) { 
                if(response.data.isAlready)
                    vm.msg = response.data.msg

                else if(response.data)
                    vm.$router.push({name: "MeusPontos"})
            })
            .catch(function(error) {
                vm.errors = error.response.data.message.errors
            })
        }
    },
    mounted() {
        let code = location.href.split('?refId=')[1] ? location.href.split('?refId=')[1].split('&')[0] : '';
        this.code = code
    }
}
</script>

<style scoped>
    .btn-send {
        background-color:#2D158B;
        color: #F2F2F2;
        width: 100%;
        border: none;
        margin: 10px auto;
    }
</style>