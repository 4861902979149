<template>
  <div class="home">
    <div id="nav-menu" v-if="isAuth">
      <router-link to="/meus-pontos">Meus Pontos</router-link>
    </div>

    <div class="msg_url alert alert-info" v-if="msg_url">{{msg_url}}</div>
    
    <div class="login-register" v-if="!recoveringCode">
      <div class="login-block" v-if="loginInProgress">
        <Login />
        <p class="notes"> Ainda não é cadastrado? <a href="javascript:void(0)" @click="goToRegister()" style="color:purple;text-decoration:none"> Clique aqui </a> e inscreva-se! </p>
      </div>

      <div class="register-block" v-else>
        <Register />
        <p class="notes"> Já tem cadastro? <a href="javascript:void(0)" @click="goToLogin()" style="color:purple;text-decoration:none"> Clique aqui </a> e faça o login! </p>
      </div>
    </div>
    
    <p class="notes" v-if="!recoveringCode"> Não recebeu o e-mail de confirmação? <a href="javascript:void(0)" @click="openRecoverCode()" style="color:purple;text-decoration:none"> Re-enviar código </a> </p>
    <p class="notes" v-else> <a href="javascript:void(0)" @click="closeRecoverCode()" style="color:purple;text-decoration:none"> Voltar </a> </p>

    <div class="recover-code">
      
      <div class="recover-block col-md-4" v-if="recoveringCode">
        <Recover />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Login from '@/components/Login.vue'
import Register from '@/components/Register.vue'
import Recover from '@/components/Recover.vue'

export default {
  name: 'Home',
  data() {
    return {
      loginInProgress: true,
      recoveringCode: false
    }
  },
  methods: {
    goToRegister() { this.loginInProgress = false },
    goToLogin() { this.loginInProgress = true },
    openRecoverCode() { this.recoveringCode = true },
    closeRecoverCode() { this.recoveringCode = false }
  },
  components: {
    Login,
    Register, 
    Recover
  },
  computed: {
    isAuth() {
      return localStorage.getItem('token_clng') ? true : false
    },
    msg_url() {
      return window.location.href.split('msg=')[1] ? decodeURI( window.location.href.split('msg=')[1] ) : ''
    }
  },
  mounted() {
    let act = location.href.split('&act=')[1] ? location.href.split('&act=')[1].split('#/')[0] : "";
    if(act == 'register') 
      this.loginInProgress = false;
  }
}
</script>

<style scoped>
  .notes {
    padding: 10px;
    color: #555;
  }
  .recover-block {
    border: 1px solid #DDD;
    margin: 40px auto;
  }
</style>