<template>
    <div class="login">

        <h1>LOGIN</h1>

        <div class="col-md-4 offset-md-4" style="padding: 10px">
            <form class="form form-horizontal" @submit.prevent="login()">
                <div class="form-group">
                    <label> E-mail </label>
                    <input type="text" class="form-control" v-model="email">
                </div>

                <div class="form-group">
                    <label> Senha </label>
                    <input type="password" class="form-control" v-model="pass">
                </div>

                <input type="submit" class="btn btn-send" value="ENTRAR">

                <div v-if="msg" class="msg alert alert-info"> {{msg}} </div>
            </form>
        </div>

    </div>
</template>

<script>
import api from '../api';
export default {
    data() {
        return {
            email: '',
            pass: '',
            msg: '',
        }
    },
    methods: {
        login() {
            let vm = this
            let email = this.email
            let password = this.pass

            api.post('/login', {
                email, password
            })
            .then(function(response) { 
                if(response.data){
                    vm.msg = response.data.msg

                    if(response.data.token) {
                        localStorage.setItem('token_clng', response.data.token)
                        vm.$router.push({name: 'MeusPontos'})
                    }
                }
            })
            .catch(function(error) {
                console.log(error)
            })
        }
    }
}
</script>

<style scoped>
    .btn-send {
        background-color: #2D158B;
        color: #F2F2F2;
        width: 100%;
        border: none;
        margin: 10px auto;
    }
</style>