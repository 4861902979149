<template>
  <div class="ranking">
    
    <h1> RANKING </h1>
    <p> {{msg}} </p>
    
    <i> <p>Líderes de pontuação</p> </i>

    <div class="status status-points col-md-4 offset-md-4">
        <table class="table table-striped" v-if="users">
            <thead>
                <th> Código </th>
                <th> Pontos </th>
            </thead>
            <tbody>
                <tr v-for="user,index in users" :key="index">
                    <td> {{user.code}} </td>
                    <td> {{user.points}} </td>
                </tr>
            </tbody>
        </table>
        <p v-else> Nenhum resultado encontrado.</p>
    </div>

    <div class="status social">
        <a href="https://instagram.com/calangodoidonft" target="_blank"> Siga no Instagram </a> &nbsp;
        <a href="https://twitter.com/calangodoidonft" target="_blank"> Siga no Twitter</a>
    </div>
  </div>
</template>

<script>
import api from '../api';
export default {
    data(){
        return {
            msg: '',
            users: '',
        }
    },
    methods: {
        loadRanking() {
            let vm = this
            
            api.get(`/ranking`)
            .then(function(response) { 
                vm.msg = response.data.msg
                vm.users = response.data
            })
            .catch(function(error) {
                vm.msg = error.message || ''
            })
        }
    },
    mounted() {
        this.loadRanking()
    }
}
</script>

<style>
    .status {
        padding: 10px;
    }
    .social a {
        text-decoration: none;
        color: #FFF;
        padding: 15px;
        background-color: #2D158B;
        border-radius: 50px;
    }
    .status-points { 
        text-align: left;
        border: 1px solid #DDD;
        border-radius: 10px;
        margin: 20px auto;
    }
</style>